import { injectHTML } from "../support/injectHtml";

const captcha = document.getElementById('checkboxCaptcha');
const honeypot = document.querySelector('.honeypot');
const piece = document.getElementById('piece');
const target = document.getElementById('target');
const message = document.getElementById('messageCaptcha');
const button = document.querySelector('button[type=submit]');

if (captcha !== null) {
    var validity = false;
    var supportsPassive = false;
    try {
        window.addEventListener("test", null, Object.defineProperty({}, 'passive', {
            get: function () {
                supportsPassive = true;
            }
        }));
    } catch (e) {}
    var wheelOpt = supportsPassive ? {
        passive: false
    } : false;
    var wheelEvent = 'onwheel' in document.createElement('div') ? 'wheel' : 'mousewheel';
    var dragged;

    piece.addEventListener("drag", function (event) {
        disableScroll();
        event.dataTransfer.setData('text/plain', null);
    }, false);

    piece.addEventListener("touchmove", function (event) {
        disableScroll();
        var touchLocation = event.targetTouches[0];
        this.style.position = 'absolute';
        this.style.transform = 'translate(-50%, -50%)';
        this.style.left = touchLocation.pageX + 'px';
        this.style.top = touchLocation.pageY + 'px';
    }, false);

    document.addEventListener("dragstart", function (event) {
        dragged = event.target;
        event.target.style.opacity = .1;
    }, false);

    document.addEventListener("dragend", function (event) {
        event.target.style.opacity = "";
    }, false);

    document.addEventListener("dragover", function (event) {
        event.preventDefault();
    }, false);

    document.addEventListener("drop", function (event) {
        event.preventDefault();
        enableScroll();
        if (event.target.id == "target") {
            validity = validateCaptcha(event.target, dragged, captcha, honeypot);
        }
    }, false);

    piece.addEventListener("touchend", event => {
        event.preventDefault();
        let coords = getCoords(target);
        let y = Math.round(parseInt(event.target.style.top));
        let x = Math.round(parseInt(event.target.style.left));
        if (
            (y >= coords.top) && (y <= (coords.top + coords.height)) &&
            (x >= coords.left) && (x <= (coords.left + coords.width))
        ) {
            validity = validateCaptcha(target, event.target, captcha, honeypot);
        } else {
            validity = unvalidateCaptcha(target, event.target, captcha, honeypot);
        }
        enableScroll();
    }, false);

    button.addEventListener('click', ev => {
        validSubmit(validity, ev);
    });
}

function validateCaptcha(target, dragged, captcha, honeypot) {
    target.style.borderColor = "green";
    dragged.parentNode.removeChild(dragged);
    target.appendChild(dragged);
    dragged.style.position = 'static';
    dragged.style.transform = 'translate(0, 0)';
    captcha.checked = true;
    message.innerText = "Merci !";
    button.classList.remove('disabled');
    honeypot.value = '03031995';
    return true;
}

function unvalidateCaptcha(target, dragged, captcha, honeypot) {
    target.style.borderColor = "red";
    dragged.parentNode.removeChild(dragged);
    target.appendChild(dragged);
    captcha.checked = false;
    honeypot.value = '666';
    message.innerText = "Mmmh j'ai besoin de mes lunettes..";
    return false;
}

function preventDefault(e) {
    e.preventDefault();
}

function disableScroll() {
    window.addEventListener('DOMMouseScroll', preventDefault, false); // older FF
    window.addEventListener(wheelEvent, preventDefault, wheelOpt); // modern desktop
    window.addEventListener('touchmove', preventDefault, wheelOpt); // mobile
}

function enableScroll() {
    window.removeEventListener('DOMMouseScroll', preventDefault, false);
    window.removeEventListener(wheelEvent, preventDefault, wheelOpt);
    window.removeEventListener('touchmove', preventDefault, wheelOpt);
}

function getCoords(elem) {
    var box = elem.getBoundingClientRect();
    var body = document.body;
    var docEl = document.documentElement;

    var scrollTop = window.pageYOffset || docEl.scrollTop || body.scrollTop;
    var scrollLeft = window.pageXOffset || docEl.scrollLeft || body.scrollLeft;

    var clientTop = docEl.clientTop || body.clientTop || 0;
    var clientLeft = docEl.clientLeft || body.clientLeft || 0;

    var top = box.top + scrollTop - clientTop;
    var left = box.left + scrollLeft - clientLeft;

    return {
        top: Math.round(top),
        left: Math.round(left),
        width: Math.round(elem.clientWidth),
        height: Math.round(elem.clientHeight),
    };
}

function validSubmit(bool, ev) {
    if (bool) {
        return true;
    } else {
        let modal = new injectHTML();
        try {
            modal.createModal('error', "Le forumaire n'est pas valide");
        } catch (error) {
            console.error(error);
        }
        preventDefault(ev);

    }
}
