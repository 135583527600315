import React from "react";
import ReactDOM from "react-dom";
import { ReactSVG } from "react-svg";

function getCookie(name) {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(';').shift();
}

async function toggleLike(url, token, ev) {
    const request = {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            click: ev !== null ? true : false,
            token: token
        })
    }
    fetch(url, request)
    .then(response => {
        if (response.ok) {
            return response.json()
        } else {
            console.log(response);
            return false
        }
    })
}

export class Articles extends React.Component
{

    constructor(props) {
        super(props)
        this.state = {
            like: false,
            token: null,
            text: 'J\'aime ce contenu',
        }
    }

    componentDidMount() {
        const cookie = getCookie('th-cookie')
        this.setState({
            token: cookie
        })
        this.handleClick();
    }

    async handleClick(ev = null) {
        const url = '/api/articles/like/' + this.props.article
        const token = this.state.token
        const request = {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                click: ev !== null ? true : false,
                token: token
            })
        }
        fetch(url, request)
        .then(response => {
            if (response.ok) {
                return response.json()
            } else {
                console.log(response);
                return false
            }
        })
        .then(json => {
            if (json.like) {
                this.setState({
                    like: true,
                    text: 'Merci',
                })
            } else {
                this.setState({
                    like: false,
                    text: 'J\'aime ce contenu',
                })
            };
        })
    }

    render() {
        return (
            <div className="flex justify-end align-center">
                <small className="mt-1">{ this.state.text }</small>
                <ReactSVG onClick={ (ev) => { this.handleClick(ev)} } className={ this.state.like ? 'active' : 'inactive' } src="/images/assets/icon/heart.svg" />
            </div>
        )
    }

}

const like = document.getElementById('Like');

if (like) {
    ReactDOM.render(<Articles article={ like.dataset.article } />, like)
}