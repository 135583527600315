let sections = document.querySelectorAll('section');
const screen = window.screen.height * -1;
// sections = Array.from(sections);

document.addEventListener('DOMContentLoaded', ev => isOnScreen(sections));
document.addEventListener('scroll', ev => isOnScreen(sections));

const isOnScreen = function(elements) {
    elements = Array.from(elements);
    elements.map( element => {
        rec = element.getBoundingClientRect();
        scroll = rec.top + (screen * 0.5);

        if ( scroll < 0 && scroll > (screen - 150)) {
            element.classList.add('visible');
        } else if (scroll > 0 || scroll < (screen - 350 )) {
            element.classList.remove('visible');
        }
    })
}