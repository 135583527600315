import React, {createRef} from 'react';
import { ReactSVG } from 'react-svg'

function Links(props) {
    return (
        <li className="nav-item">
            <a className="link-hover font-light font-sans" href={props.href} title={"lien vers la page" + props.title}>
                {props.title}
            </a>
        </li>
    )
}

function CloseToggle(props) {
    return (
        <ReactSVG className="close-icon" src="/images/assets/icon/x.svg" onClick={props.onClick} />
    )
}

export class VerticalNavigation extends React.Component
{
    wrapperRef = createRef();

    renderLinks(i) {
        return (
            <Links 
                href={this.props.href[i]}
                title={this.props.title[i]}
                key={i}
            />
        )
    }

    render() {
        let isOpen = this.props.isOpen ? 'open' : 'close';
        return (
            <div className={'vertical-nav-container ' + isOpen}>
                <div className="vertical-nav">
                    <CloseToggle onClick={this.props.closeNav} />
                    <ul className="tabs-container">
                        {this.props.title.map( (value, index) => { return this.renderLinks(index) } )}
                    </ul>
                    <div className="nav-footer">
                        <a href="/" title="TESSIER HERVE Avocats">
                            <img src="/images/assets/logo/logo-250x333.png" className="nav-logo" alt="logo du cabinet d'avocats rennais TESSIER HERVE Avocats" />
                        </a>
                        <ul className="nav-footer-links">
                            <li>
                                <a href="/politique-confidentialite" className="font-light font-sans">
                                    Confidentialité
                                </a>
                            </li>
                            <li>
                                <a href="/mentions-legales" className="font-light font-sans">
                                    Mentions
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="overlay" onClick={this.props.closeNav}></div>
            </div>
        )
    }
}