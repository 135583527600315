import React from "react";
import ReactDOM from "react-dom";

class PartnersSection extends React.Component
{

    constructor(props) {
        super(props);
        this.handleScroll = this.handleScroll.bind(this)
        this.state = {
            active: false,
            scroll: 0,
        }
    }

    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }

    handleScroll(e) {
        let element = document.getElementById('PartnersContainer'),
            rec = element.getBoundingClientRect();
        this.setState({
            scroll: rec.top - element.offsetHeight,
        });
    }

    render() {
        let style1 = null,
            style2 = null;
        if (this.state.scroll > 0) {   
            style1 = { 
                transform: `translateX(-${this.state.scroll}px` 
            };
            style2 = { 
                transform: `translateX(${this.state.scroll}px` 
            };
        }
        return (
            <div className="partners-photo-container" onScroll={this.handleScroll} >
                <div className="partner-img partner1" style={style1}>
                    <a href="/associes/m-tessier">
                        <img src="/images/assets/layout/partners2.jpg" alt="photo de maitre TESSIER avocat au cabinet TESSIER HERVE Avocats" />
                    </a>
                </div>
                <div className="partner-img partner2" style={style2}>
                    <a href="/associes/l-herve">
                        <img src="/images/assets/layout/partners1.jpg" alt="photo de maitre HERVE avocat au cabinet TESSIER HERVE Avocats" />
                    </a>
                </div>
            </div>
        )
    }

}
let PartnersContainer = document.getElementById('PartnersContainer');
if (PartnersContainer) {
    ReactDOM.render(<PartnersSection />, PartnersContainer);
}