import React from "react";
import ReactDOM from "react-dom";

// https://www.npmjs.com/package/react-cookie-consent
import CookieConsent from "react-cookie-consent";

const notice = <CookieConsent
                    location="bottom"
                    buttonText="D'accord"
                    containerClasses="cookie-notice"
                    cookieName="th-cookie"
                    cookieValue= { (Math.random() + 1).toString(36).substring(2) }
                    buttonClasses="btn btn-sm cookie-btn"
                    disableStyles={ true }
                >
                    Notre site utilise des cookies pour comprendre et améliorer son utilisation. Pour en savoir plus rendez-vous sur notre page <a href="/politique-confidentialite" target="_blank" title="Polique de confidentialité">politique de confidentialité</a>.
                </CookieConsent>;

ReactDOM.render(notice, document.getElementById('CookieNotice'));