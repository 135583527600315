import React from 'react';
import ReactDOM from 'react-dom';
import { ReactSVG } from 'react-svg'
import { VerticalNavigation } from './navigation-verticale';

class Navigation extends React.Component
{
    constructor(props) {
        super(props);
        this.state = {
            isLoaded: false,
            toggleActive: false,
            title: [],
            href: [],
        }
    }

    componentDidMount() {
        fetch('/api/page-builder/routes')
            .then(res => res.json())
            .then(json => {
                this.setState({
                    isLoaded: true,
                    title: json.routesTitle,
                    href: json.routesPath,
                })
            });
    }

    handleClick() {
        this.setState({
            toggleActive: !this.state.toggleActive,
        });
    }

    renderVerticalNavigation() {
        return <VerticalNavigation 
            isOpen={this.state.toggleActive}
            title={this.state.title}
            href={this.state.href}
            closeNav={ () => this.handleClick() }
        />
    }

    render() {
        const svgPath = '/images/assets/icon/';
        return (
            <div className="navigations">
                { this.renderVerticalNavigation() }
                <div className="top-nav">
                    <div className="menu-toggle nav-icon">
                        <ReactSVG src={svgPath + 'menu.svg'} onClick={ () => this.handleClick() }/>
                    </div>
                    <div className="contact-toggle nav-icon">
                        <div>
                            <a href="/contact" title="Page de contact">
                                <ReactSVG src={svgPath + 'mail.svg'} />
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

ReactDOM.render(<Navigation />, document.getElementById('Navigation'));