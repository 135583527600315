const preloader = document.getElementById('Preloader')
const domain = location.origin
const regex = new RegExp(domain, 'g')

if (!regex.test(document.referrer)) {
    
    document.addEventListener('DOMContentLoaded', function(ev) {
        setTimeout(() => {
            preloader.classList.remove('active')
        }, 3000);
    } )

    } else {
        preloader.remove()
}