import React from "react";
import ReactDOM from "react-dom";
import { ReactSVG } from "react-svg";

class Modal extends React.Component
{

    constructor(props) {
        super(props);
        this.state = {
            url: null,
            data: {
                type: 'info',
                title: '',
                desc: ''
            },
            active: false,
        }
    }

    componentDidMount() {
        let modals = document.querySelectorAll('.modal'),
            modalsClick = document.querySelectorAll('.modalHandler');
        modals = Array.from(modals);
        modalsClick = Array.from(modalsClick);
        modals.map((modal) => this.setModal(modal))
        modalsClick.map((modal) => this.setModalHandler(modal))
    }

    setModal(modal) {
        
        this.setState( {
            active: true,
            data: {
                type: modal.dataset.type,
                desc: modal.dataset.desc,
                title: modal.dataset.title,
            },
        });
    }

    setModalHandler(modal) {
        let desc = null,
            title = null,
            type = 'info';

        modal.dataset.desc !== undefined ? desc = modal.dataset.desc : null;
        modal.dataset.title !== undefined ? title = modal.dataset.title : null;
        modal.dataset.type !== undefined ? type = modal.dataset.type : null;
        
        modal.addEventListener('click', (ev) => {
            this.setState( {
                active: true,
                data: {
                    type: type,
                    desc: desc,
                    title: title,
                },
            });
        });
    }

    handleClose() {
        this.setState( {
            active: false,
        });
    }

    htmlDecode(input){
        var e = document.createElement('div');
        e.innerHTML = input;
        return e.childNodes.length === 0 ? "" : e.childNodes[0].nodeValue;
    }

    render() {
        let validTypes = ['info', 'warning', 'alert'],
            type = this.state.data.type;
        validTypes.includes(type) ? null : type = 'info';
        
        if (this.state.active) {
            return (
                <div className={ 'modal-container ' + type }>
                    <div className="modal-close">
                        <ReactSVG src="/images/assets/icon/x.svg" onClick={ () => this.handleClose() } />
                    </div>
                    <div className="modal-icon">
                        <ReactSVG src={ '/images/assets/icon/' + type + '.svg' } />
                    </div>
                    <div className="modal-content">
                        <div className="modal-title">
                            { this.state.data.title }
                        </div>
                        <div className="modal-desc" dangerouslySetInnerHTML={{ __html: this.state.data.desc }} />
                    </div>

                </div>
            );
        } else {
            return (
                <div></div>
            )
        }
    }

}

ReactDOM.render(<Modal />, document.getElementById('Modal'));
